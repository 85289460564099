import { useLocation } from '@remix-run/react';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';

import { apiService } from '../../../services/api-service';
import { type Organization } from '../../../types/organization';
import { err2s } from '../../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../../Loading';
import { SlackInstallButton } from '../../Slack';

function OrganizationDoNotUseSlackModal(props: {
  organization: Organization;
  onCancel: () => void;
  onConfirm: () => void;
}): JSX.Element {
  const { organization, onCancel, onConfirm } = props;

  const location = useLocation();

  const {
    trigger: handleDoNotUseSlack,
    isMutating,
    error,
  } = useSWRMutation(`/organizations/${organization.id}`, async () => {
    await apiService.organization.updateOrganizationUsesSlack(
      organization.id,
      false
    );
    onConfirm();
  });

  return (
    <ModalWrapper
      containerClassName='w-160'
      borderStyle='white'
      onClose={onCancel}
    >
      <div className='w-full px-25 py-10 text-center'>
        <header className='text-2xl font-medium'>Don’t use Slack?</header>

        <p className='mt-10 text-sms'>
          Luna Park uses Slack to make the experience of using our platform more{' '}
          <strong>convenient</strong> and <strong>accessible</strong> for your
          team members
        </p>
        <p className='mt-4 text-sms'>
          If your organization doesn’t use Slack,{' '}
          <strong>that’s totally fine!</strong> You can still access all of our
          games and live experiences directly from our website!
        </p>

        <footer className='mt-5 flex flex-col items-center'>
          {error && (
            <div className='text-sms text-red-002 mb-2'>{err2s(error)}</div>
          )}

          <div className='flex items-center gap-2.5'>
            <button
              className='btn-primary w-52 h-10 flex justify-center items-center'
              onClick={() => handleDoNotUseSlack()}
              disabled={isMutating}
            >
              {isMutating && <Loading text='' />}
              We don’t use Slack
            </button>
            <SlackInstallButton
              text='Connect Slack'
              from={location.pathname}
              className='btn-primary w-52 h-10'
            />
          </div>

          <button className='mt-5 btn-secondary w-33 h-10' onClick={onCancel}>
            Close
          </button>
        </footer>
      </div>
    </ModalWrapper>
  );
}

export interface TriggerDoNotUserSlackModalProps {
  organization: Organization;
  onConfirmed?: () => void;
}

export function useTriggerOrganizationDoNotUseSlackModal(): (
  props: TriggerDoNotUserSlackModalProps
) => void {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    (props: TriggerDoNotUserSlackModalProps) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <OrganizationDoNotUseSlackModal
            organization={props.organization}
            onCancel={p.internalOnCancel}
            onConfirm={() => {
              p.internalOnConfirm();
              if (props.onConfirmed) {
                props.onConfirmed();
              }
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}
