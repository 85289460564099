import useSWR from 'swr';

import { apiService } from '../../services/api-service';

export function useOnboardingTasks(orgId?: string | null) {
  return useSWR(
    orgId ? ['onboarding-tasks', orgId] : null,
    async ([_, orgId]) =>
      (await apiService.onboarding.listTasks(orgId)).data.tasks
  );
}
